<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="box box-widget widget-user-2">
                    <div class="widget-user-header bg-default">
                        <h3 style="margin-left: 0px;" class="widget-user-username">
                            <div class="row">
                                <span class="col-xs-10">{{group.company_name}}</span>
                            </div>
                        </h3>
                    </div>
                    <div class="box-footer no-padding">
                        <ul class="nav nav-stacked hidden-sm hidden-xs">
                            <li class="active"><a href="#info_sub" data-toggle="tab"><i class="fa fa-home"></i> Informace o účtu</a></li>
                            <li v-if="user.cus == 0 && !user.isEmployee"><a href="#subscriptions_sub" data-toggle="tab"><i class="fa fa-credit-card"></i> Dostupná předplatná</a></li>
                            <!-- <li><a href="#settings_sub" data-toggle="tab"><i class="fa fa-user"></i> Upravit profil</a></li> -->
                            <li><a href="#password_sub" data-toggle="tab"><i class="fa fa-lock"></i> Změna hesla</a></li>
                            <li v-if="user.cus == 0"><a href="#invoice_info_sub" data-toggle="tab"><i class="fa fa-file-text-o"></i> Fakturační údaje <span  v-if="!checkInvoiceInfo()" class="text-yellow"><i class="fa fa-exclamation"></i></span></a></li>
                            <li v-if="user.cus == 0 && !user.isEmployee"><a href="#employees" data-toggle="tab"><i class="fa fa-wrench"></i> Seznam zaměstnanců</a></li>
                            <li><a href="#reservation_tab" data-toggle="tab"><i class="fa fa-bookmark"></i> Rezervační systém</a></li>
                            <li v-if="user.cus == 0 && !user.isEmployee"><a href="#export_sub" data-toggle="tab"><i class="fa fa-download"></i> Export dat</a></li>
                        </ul>
                    </div>
                </div>
                <div v-if="user.cus" class="box box-widget">
                    <!-- Add the bg color to the header using any of the bg-* classes -->
                    <div class="box-header">
                        <h3 class="box-title">Informace o autoservisu</h3>
                    </div>
                    <div class="box-footer no-padding">
                        <ul class="nav nav-stacked">
                            <li><a href="#">Servis<span class="pull-right">{{group.station.company_name}}</span></a></li>
                            <li><a href="#">Mechanik<span class="pull-right">{{group.station.acc_name}}</span></a></li>
                            <li v-if="group.station.email"><a :href="'mailto:' + group.station.email">Email <a href="#" class="pull-right">{{group.station.email}}</a></a></li>
                            <li v-if="group.station.phone"><a :href="'tel:' + user.phone">Telefon<a href="#" class="pull-right">{{group.station.phone}}</a></a></li>
                            <li v-if="group.station.acc_email && group.station.acc_email != group.station.email"><a :href="'mailto:' + group.station.acc_email">Email mechanika <a href="#" class="pull-right">{{group.station.acc_email}}</a></a></li>
                            <li v-if="group.station.acc_phone && group.station.acc_phone != group.station.phone"><a :href="'tel:' + group.station.acc_phone">Telefon na mechanika<a href="#" class="pull-right">{{group.station.acc_phone}}</a></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs visible-sm visible-xs">
                        <li class="active"><a href="#info_sub" data-toggle="tab"><i class="fa fa-home"></i> Informace o účtu</a></li>
                        <li><a href="#subscriptions_sub" data-toggle="tab"><i class="fa fa-credit-card"></i> Dostupná předplatná</a></li>
                        <!-- <li><a href="#settings_sub" data-toggle="tab"><i class="fa fa-user"></i> Upravit profil</a></li> -->
                        <li><a href="#password_sub" data-toggle="tab"><i class="fa fa-lock"></i> Změna hesla</a></li>
                        <li><a href="#invoice_info_sub" data-toggle="tab"><i class="fa fa-file-text-o"></i> Fakturační údaje <span v-if="!checkInvoiceInfo()" class="text-yellow"><i class="fa fa-exclamation"></i></span></a></li>
                        <li v-if="user.cus == 0 && !user.isEmployee"><a href="#employees" data-toggle="tab"><i class="fa fa-wrench"></i> Seznam zaměstnanců</a></li>
                        <li><a href="#reservation_tab" data-toggle="tab"><i class="fa fa-bookmark"></i> Rezervační systém</a></li>
                        <!-- <li><a href="#invoices_sub" data-toggle="tab"><i class="fa fa-list"></i> Přehled faktur</a></li> -->
                        <!-- <li><a href="#support_sub" data-toggle="tab"><i class="fa  fa-support"></i> Podpora</a></li> -->
                        <li v-if="user.cus == 0 && !user.isEmployee"><a href="#export_sub" data-toggle="tab"><i class="fa fa-download"></i> Export dat</a></li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active" id="info_sub">
                            <div class="box-header with-border">
                                <h3 class="box-title">Informace o účtu</h3>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-6">
                                    <ul class="list-group list-group-unbordered">
                                        <li class="list-group-item">
                                            <b>Jméno a příjmení</b> 
                                            <span class="pull-right">
                                                {{user.fullname}}<!--<a href="#settings_sub" data-toggle="tab"><button class="btn btn-default btn-xs" style="border: none; margin-top: 0px">Doplňte</button></a>-->
                                            </span>
                                        </li>
                                        <li class="list-group-item">
                                            <b>Email</b> <a class="pull-right">{{user.email}}</a>
                                        </li>
                                        <li class="list-group-item">
                                            <b>Telefon</b> 
                                            <span class="pull-right">
                                                <a class="pull-right">{{group.phone}}</a> <!--<a href="#settings_sub" data-toggle="tab"><button class="btn btn-default btn-xs" style="border: none; margin-top: 0px" v-if="!user.phone">Doplňte</button></a>-->
                                            </span>
                                        </li>
                                        <li class="list-group-item">
                                            <b>Datum registrace</b> <span class="pull-right">{{user.registered}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="user.cus == 0 && !user.isEmployee">
                                <div class="box-header with-border">
                                    <h3 class="box-title">Současné předplatné</h3>
                                </div>
                                <br>
                                <div class="row">
                                    <div v-if="group.timespent.plan_id == 0" class="col-md-4 col-sm-6 col-xs-12">
                                        <div class="info-box">
                                            <span class="info-box-icon bg-blue"><i class="ion ion-ios-bulb-outline"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Neomezený přístup</span>
                                                <span class="info-box-number">Neomezený čas</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="group.timespent.plan_id == 1" class="col-md-4 col-sm-6 col-xs-12">
                                        <div class="info-box">
                                            <span class="info-box-icon bg-blue"><i class="ion ion-ios-bulb-outline"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Testerský přístup</span>
                                                <span class="info-box-number">Neomezený čas</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="group.timespent.plan_id == 2 || group.timespent.trial" class="col-md-4 col-sm-6 col-xs-12">
                                        <div class="info-box">
                                            <span class="info-box-icon bg-teal"><i class="ion ion-ios-cafe-outline"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Bezplatný přístup</span>
                                                <span class="info-box-number">{{ group.remaining_cars == 0 ? "Již si nemůžete evidovat žádný další vůz zdarma" : "Můžete evidovat ještě " + group.remaining_cars + " vozidel" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="group.timespent.plan_id == 3" class="col-md-4 col-sm-6 col-xs-12">
                                        <div class="info-box">
                                            <span class="info-box-icon bg-yellow"><i class="ion ion-ios-calendar-outline"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Měsíční předplatné</span>
                                                <span class="info-box-number">250 Kč/měsíc</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="group.timespent.plan_id == 4" class="col-md-4 col-sm-6 col-xs-12">
                                        <div class="info-box">
                                            <span class="info-box-icon bg-green"><i class="ion ion-ios-timer-outline"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Roční předplatné</span>
                                                <span class="info-box-number">233 Kč/měsíc</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div v-else-if="group.timespent.plan_id == 5" class="col-md-4 col-sm-6 col-xs-12">
                                        <div class="info-box">
                                            <span class="info-box-icon bg-green"><i class="ion ion-ios-timer-outline"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Předplatné na 5 let</span>
                                                <span class="info-box-number">208 Kč/měsíc</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="user.cus == 0 && !user.isEmployee" class="tab-pane" id="subscriptions_sub">
                            <div class="box-header with-border">
                                <h3 class="box-title">Současné předplatné</h3>
                            </div>
                            <br>
                            <div class="row">
                                <div v-if="group.timespent.plan_id == 0" class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-blue"><i class="ion ion-ios-bulb-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Neomezený přístup</span>
                                            <span class="info-box-number">Neomezený čas</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="group.timespent.plan_id == 1" class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-blue"><i class="ion ion-ios-bulb-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Testerský přístup</span>
                                            <span class="info-box-number">Neomezený čas</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="group.timespent.plan_id == 2 || group.timespent.trial || group.timespent.plan_id == 0" class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-teal"><i class="ion ion-ios-cafe-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Bezplatný přístup</span>
                                            <span class="info-box-number">{{ group.remaining_cars == 0 ? "Již si nemůžete evidovat žádný další vůz zdarma" : "Můžete evidovat ještě " + group.remaining_cars + " vozidel" }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="group.timespent.plan_id == 3" class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-yellow"><i class="ion ion-ios-calendar-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Měsíční předplatné</span>
                                            <span class="info-box-number">250 Kč/měsíc</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="group.timespent.plan_id == 4" class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-green"><i class="ion ion-ios-timer-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Roční předplatné</span>
                                            <span class="info-box-number">233 Kč/měsíc</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div v-else-if="group.timespent.plan_id == 5" class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-green"><i class="ion ion-ios-timer-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Předplatné na 5 let</span>
                                            <span class="info-box-number">208 Kč/měsíc</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="box-header with-border">
                                <h3 class="box-title">Dostupná předplatná</h3>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-yellow"><i class="ion ion-ios-calendar-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Měsíční předplatné</span>
                                            <span class="info-box-number">250 Kč/měsíc</span>
                                            <span v-if="group.timespent.plan_id == 3" class="info-box-text text-green pull-left"><i class="fa fa-check"></i> Aktivní</span>
                                        </div>
                                        <div class="pull-right">
                                            <button v-if="group.timespent.plan_id == 3" @click="cancelPlan()" type="button" class="btn btn-danger" style="border: none; margin-right: 5px"><span><i class="fa fa-edit"></i> Zrušit</span></button>
                                            <button v-else @click="selectPlan(3)" :disabled="group.timespent.tester" type="button" class="btn btn-default" style="border: none; margin-right: 5px"><span><i class="fa fa-edit"></i> Vybrat</span></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-green"><i class="ion ion-ios-timer-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Roční předplatné</span>
                                            <span class="info-box-number">233 Kč/měsíc</span>
                                            <span v-if="group.timespent.plan_id == 4" class="info-box-text text-green pull-left"><i class="fa fa-check"></i> Aktivní</span>
                                        </div>
                                        <div class="pull-right">
                                            <button v-if="group.timespent.plan_id == 4" @click="cancelPlan()" type="button" class="btn btn-danger" style="border: none; margin-right: 5px"><span><i class="fa fa-edit"></i> Zrušit</span></button>
                                            <button v-else @click="selectPlan(4)" :disabled="group.timespent.tester" type="button" class="btn btn-default" style="border: none; margin-right: 5px"><span><i class="fa fa-edit"></i> Vybrat</span></button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4 col-sm-6 col-xs-12">
                                    <div class="info-box">
                                        <span class="info-box-icon bg-green"><i class="ion ion-ios-timer-outline"></i></span>
                                        <div class="info-box-content">
                                            <span class="info-box-text">Předplatné na 5 let</span>
                                            <span class="info-box-number">208 Kč/měsíc</span>
                                            <span v-if="group.timespent.plan_id == 5" class="info-box-text text-green pull-left"><i class="fa fa-check"></i> Aktivní</span>
                                        </div>
                                        <div class="pull-right">
                                            <button v-if="group.timespent.plan_id == 5" @click="cancelPlan()" type="button" class="btn btn-danger" style="border: none; margin-right: 5px"><span><i class="fa fa-edit"></i> Zrušit</span></button>
                                            <button v-else @click="selectPlan(5)" :disabled="group.timespent.tester" type="button" class="btn btn-default" style="border: none; margin-right: 5px"><span><i class="fa fa-edit"></i> Vybrat</span></button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-xs-12">
                                    <p>Ceny jsou uvedeny s DPH.</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="password_sub">
                            <div class="box-header with-border">
                                <h3 class="box-title">Změna hesla</h3>
                            </div>
                            <br>
                            <div class="row">
                                <div v-if="changePassSuccess" class="col-lg-12">
                                    <div class="alert alert-success alert-dismissible">
                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                        <h4><i class="icon fa fa-check"></i> Heslo úspěšně změněno.</h4>
                                    </div>
                                </div>
                                <div v-if="errors.length" class="col-lg-12">
                                    <div class="alert alert-danger alert-dismissible">
                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                        <h4><i class="icon fa fa-ban"></i> Heslo nemohlo být změněno.</h4>
                                        <p v-for="err in errors" :key="err">{{err}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-horizontal">
                                <div :class="{'form-group': true, 'has-error': ($v.formNewPass.old_pass.$error || this.errors.includes('Špatné heslo.'))}">
                                    <label for="formerPassword" class="col-sm-2 control-label">Původní heslo</label>
                                    <div class="col-sm-10">
                                        <input id="formerPassword" class="form-control"  v-model="$v.formNewPass.old_pass.$model" type="password">
                                    </div>
                                </div>
                                <div :class="{'form-group': true, 'has-error': $v.formNewPass.new_pass.$error}">
                                    <label for="newPassword" class="col-sm-2 control-label">Nové Heslo</label>
                                    <div class="col-sm-10">
                                        <input id="newPassword" class="form-control"  v-model="$v.formNewPass.new_pass.$model" type="password">
                                        <span v-if="!$v.formNewPass.new_pass.minLength" class="help-block">Musí mít minimálně 6 znaků!</span>
                                        <span v-if="!$v.formNewPass.new_pass.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                    </div>
                                </div>
                                <div :class="{'form-group': true, 'has-error': $v.formNewPass.new_pass2.$error}">
                                    <label for="newPasswordConfirm" class="col-sm-2 control-label">Heslo znovu</label>
                                    <div class="col-sm-10">
                                        <input id="newPasswordConfirm" class="form-control"  v-model="$v.formNewPass.new_pass2.$model" type="password">
                                        <span v-if="!$v.formNewPass.new_pass2.sameAsPassword" class="help-block">Hesla musí být stejná!</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                        <button type="button" class="btn btn-success" @click="changePass()" :disabled="$v.formNewPass.$invalid">Změnit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="employees">
                            <div class="box-header with-border">
                                <h3 class="box-title">Seznam zaměstnanců</h3>
                            </div>
                            <div class="box-body">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="input-group input-group-sm" style="width: 100%">
                                            <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                                        </div>
                                    </div>
                                    <div class="col-lg-8">
                                        <router-link :to="{ name: 'EmployeeAdd' }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat zaměstnance</router-link>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                                <table class="table table-hover">
                                    <thead>
                                    <tr>
                                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="employee in employees.filter((employee) => employee.employee)" :key="employee.accountid">
                                            <td>{{employee.forename}}</td>
                                            <td>{{employee.surname}}</td>
                                            <td>{{employee.email}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane" id="invoice_info_sub">
                            <div class="box-header with-border">
                                <h3 class="box-title">Fakturační údaje</h3>
                            </div>
                            <br>
                            <div class="form-horizontal">
                                <!-- <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.company_name.$error}">
                                    <label for="inputCompanyName" class="col-sm-2 control-label">Jméno servisu (popř. osoby)</label>
                                    
                                    <div class="col-sm-10">
                                    <input id="inputCompanyName" class="form-control"  v-model="$v.invoiceInfoForm.company_name.$model" type="text">
                                    <span v-if="!$v.invoiceInfoForm.company_name.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.invoiceInfoForm.company_name.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                    </div>
                                    </div> -->
                                <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.ico.$error}">
                                    <label for="inputICO" class="col-sm-2 control-label">IČO</label>
                                    <div class="col-sm-10">
                                        <input id="inputICO" class="form-control"  v-model="$v.invoiceInfoForm.ico.$model" type="text">
                                        <span v-if="!$v.invoiceInfoForm.ico.required" class="help-block">Povinný údaj</span>
                                        <span v-if="!$v.invoiceInfoForm.ico.minLength" class="help-block">Musí mít minimálně 7 znaků!</span>
                                        <span v-if="!$v.invoiceInfoForm.ico.maxLength" class="help-block">Může mít maximálně 9 znaků!</span>
                                    </div>
                                </div>
                                <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.dic.$error}">
                                    <label for="inputDIC" class="col-sm-2 control-label">DIČ</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="inputDIC" v-model="$v.invoiceInfoForm.dic.$model">
                                        <span v-if="!$v.invoiceInfoForm.dic.minLength" class="help-block">Musí mít minimálně 8 znaků!</span>
                                        <span v-if="!$v.invoiceInfoForm.dic.maxLength" class="help-block">Může mít maximálně 12 znaků!</span>
                                    </div>
                                </div>
                                <div class="form-horizontal">
                                    <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.street.$error}">
                                        <label class="col-sm-2 control-label">Ulice a č.p.</label>
                                        <div class="col-sm-10">
                                            <input class="form-control"  v-model="$v.invoiceInfoForm.street.$model" type="text">
                                            <span v-if="!$v.invoiceInfoForm.street.required" class="help-block">Povinný údaj</span>
                                            <span v-if="!$v.invoiceInfoForm.street.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-horizontal">
                                    <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.city.$error}">
                                        <label class="col-sm-2 control-label">Město</label>
                                        <div class="col-sm-10">
                                            <input class="form-control"  v-model="$v.invoiceInfoForm.city.$model" type="text">
                                            <span v-if="!$v.invoiceInfoForm.city.required" class="help-block">Povinný údaj</span>
                                            <span v-if="!$v.invoiceInfoForm.city.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-horizontal">
                                    <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.zip.$error}">
                                        <label for="inputZip" class="col-sm-2 control-label">PSČ</label>
                                        <div class="col-sm-10">
                                            <input id="inputZip" class="form-control"  v-model="$v.invoiceInfoForm.zip.$model" type="number">
                                            <span v-if="!$v.invoiceInfoForm.zip.required" class="help-block">Povinný údaj</span>
                                            <span v-if="!$v.invoiceInfoForm.zip.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                            <span v-if="!$v.invoiceInfoForm.zip.maxLength" class="help-block">Může mít maximálně 6 znaků!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-horizontal">
                                    <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.bank_account.$error}">
                                        <label for="bankAccount" class="col-sm-2 control-label">Bankovní účet</label>
                                        <div class="col-sm-10">
                                            <input id="bankAccount" class="form-control"  v-model="$v.invoiceInfoForm.bank_account.$model" type="text">
                                            <span v-if="!$v.invoiceInfoForm.bank_account.required" class="help-block">Povinný údaj</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-horizontal">
                                    <div :class="{'form-group': true, 'has-error': $v.invoiceInfoForm.business_register.$error}">
                                        <label for="bankAccount" class="col-sm-2 control-label">Obchodní rejstřík</label>
                                        <div class="col-sm-10">
                                            <input id="bankAccount" class="form-control" v-model="$v.invoiceInfoForm.business_register.$model" type="text">
                                            <span v-if="!$v.invoiceInfoForm.business_register.required" class="help-block">Povinný údaj</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-horizontal">
                                    <div class="form-group">
                                        <label class="col-sm-2 control-label">Země</label>
                                    
                                        <div class="col-sm-10">
                                            <select v-model="formResponses.invoice_country" class="form-control" style="width: 100%;">
                                                <option>Česká republika</option>
                                                <option>Slovensko</option>
                                                <option>Německo</option>
                                                <option>Rakousko</option>
                                                <option>Polsko</option>
                                            </select>
                                        </div>
                                    </div>
                                    </div> -->
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                        <div class="checkbox">
                                            <label>
                                                <input v-model="taxed" type="checkbox"> Plátce daně                                    
                                            </label>
                                            <span class="help-block"><span class="text-yellow"><i class="fa fa-exclamation"></i></span>  Při změně tohoto údaje nedojde ke změně DPH v již existujících úkonech. V případě otevřených úkonů je zapotřebí DPH manuálně upravit.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                        <div class="checkbox">
                                            <label>
                                            <input v-model="invoice" type="checkbox"> Povolit fakturaci                                  
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="invoice">
                                    <div class="box-header with-border">
                                        <h3 class="box-title">Nastavení fakturace</h3>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-10">
                                            <div class="checkbox">
                                                <label>
                                                    <input v-model="custom_invoice_number" type="checkbox"> Vlastní číselná řada faktur                                 
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-4">
                                            <label for="default_invoice_number" class="control-label">Začátek číselné řady faktur</label>
                                            <input id="default_invoice_number" class="form-control" v-model="$v.invoiceSettingsForm.default_invoice_number.$model" :disabled="!custom_invoice_number" type="number">
                                            <span v-if="!$v.invoiceSettingsForm.default_invoice_number.minValue && custom_invoice_number" class="help-block">Začátek číselné řady musí být větší jak číslo posledního úkonu nebo faktury: {{ $v.invoiceSettingsForm.default_invoice_number.$params.minValue.min - 1 }}</span>
                                            <span v-if="!$v.invoiceSettingsForm.default_invoice_number.required && custom_invoice_number" class="help-block">Povinný údaj</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                        <button type="button" class="btn btn-success" @click="changeInvoiceInfo()" :disabled="$v.invoiceInfoForm.$invalid || (custom_invoice_number && $v.invoiceSettingsForm.$invalid && $v.invoiceSettingsForm.default_invoice_number.$dirty)">Uložit údaje</button>
                                        <!-- <button type="button" class="btn btn-danger pull-right" @click="mntDelete()">Odstranit záznam</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="reservation_tab">
                            <div class="form-group">
                                <label style="margin-right: 1rem">Otevírací doba od</label>
                                <flat-pickr v-model="openingTimeFrom" :config="config"></flat-pickr>
                            </div>
                            <div class="form-group">
                                <label style="margin-right: 1rem">Otevírací doba do</label>
                                <flat-pickr v-model="openingTimeTo" :config="config"></flat-pickr>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                    <input v-model="reservation" type="checkbox"> Chci zobrazit servis v rezervačním systému                                  
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-success" @click="changeInvoiceInfo(true)">Uložit údaje</button>
                            </div>
                        </div>
                        <div class="tab-pane" id="invoices_sub">
                            <div class="box-header with-border">
                                <h3 class="box-title">Přehled faktur</h3>
                            </div>
                        </div>
                        <div class="tab-pane" id="support_sub">
                            <div class="box-header with-border">
                                <h3 class="box-title">Kontakt na podporu</h3>
                            </div>
                        </div>
                    </div>
                    <!-- /.tab-content -->
                </div>
                <!-- /.nav-tabs-custom -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
</template>

<script>
const name = 'PersonalInfo';

import { mapGetters, mapActions } from "vuex";

import flatPickr from 'vue-flatpickr-component';
import { Czech } from 'flatpickr/dist/l10n/cs.js';
import 'flatpickr/dist/flatpickr.css';

// Vuelidate
import { required,  minLength, maxLength, email, helpers, requiredIf, sameAs, minValue, numeric } from 'vuelidate/lib/validators'

const phone_format = helpers.regex('phone_format', /^[+]?[()/0-9. -]{9,}$/)

import Vue from 'vue'

import api from '../store/classes/api'; 

export default {
    components: {
        flatPickr
    },
    name,
    data() {
        return {
            config: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                locale: Czech,
                minuteIncrement: 30
            },
            openingTimeFrom: null,
            openingTimeTo: null,
            filterCars: '',
            page: null,
            filter: '',

            canlog: null,
            admin: null,
            
            errors: [],

            changePassSuccess: false,
            formNewPass: {
                old_pass: '',
                new_pass: '',
                new_pass2: ''
            },

            formResponses: {
                fullname: '',
                contact_email: '',
                contact_phone: ''
            },

            invoiceInfoForm: {
                // company_name: '',
                ico: '',
                dic: '',
                street: '',
                city: '',
                zip: '',
                bank_account: '',
                business_register: ''
            },

            invoiceSettingsForm: {
                default_invoice_number: 0,
            },

            taxed: false,
            invoice: false,
            custom_invoice_number: false,
            reservation: false,
            billing_plan_id: null,
            nextInvoiceNumber: 0
        }
    },
    validations() {
        return {
            formNewPass: {
                old_pass: {
                    required
                },
                new_pass: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(32)
                },
                new_pass2: {
                    required,
                    sameAsPassword: sameAs('new_pass')
                }
            },
            formResponses: {
                fullname: {
                    required,
                    maxLength: maxLength(32)
                },
                contact_email: {
                    email,
                    required: requiredIf(function() {
                        return this.canlog
                    })
                },
                contact_phone: {
                    phone_format
                }
            },
            invoiceInfoForm: {
                // company_name: {
                //     required,
                //     maxLength: maxLength(32)
                // },
                ico: {
                    required,
                    minLength: minLength(7),
                    maxLength: maxLength(9)
                },
                dic: {
                    minLength: minLength(8),
                    maxLength: maxLength(12)
                },
                street: {
                    required,
                    maxLength: maxLength(32)
                },
                city: {
                    required,
                    maxLength: maxLength(32)
                },
                zip: {
                    required,
                    minLength: minLength(5),
                    maxLength: maxLength(6)
                },
                business_register: {
                    required,
                    minLength: minLength(0),
                    maxLength: maxLength(80)
                },
                bank_account: {
                    required: requiredIf(function () {
                        return this.invoice
                    })
                },
            },
            invoiceSettingsForm: {
                default_invoice_number: {
                    required: requiredIf(function () {
                        return this.custom_invoice_number
                    }),
                    numeric,
                    minValue: minValue(this.nextInvoiceNumber)
                }
            }
        }
    },
    computed: {
        ...mapGetters(['getAllPages', 'currentMaintainer', 'currentMaintainerId', 'user', 'group', 'employees', 'invoiceInfo']),    
    }, 
    methods: {
        ...mapActions(['makeActiveTab', 'addTab', 'changePassword', 'fetchInvoiceInfo', 'updateInvoiceInfo', 'fetchUser', 'fetchEmployees', 'fetchInvoiceNumber', 'setPhone']),
        getTime(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours();
            const min = dt.getMinutes();
            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;  
        },

        getDate(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}.${mth}.${yrs}`;  
        },

        mntUpdate(){
            this.updateMaintainer({
                maintainerid: this.currentMaintainerId,
                fullname: this.formResponses.fullname,
                email: this.formResponses.contact_email,
                phone: this.formResponses.contact_phone,
                canlog: this.canlog,
                admin: this.admin
            }).then(() => {
                this.fetchMaintainer(this.currentMaintainerId)
            })
        },

        async changePass(){
            this.errors = [];
            const res = await api.changePassword({
                uid: this.user.uid,
                cus: this.user.cus,
                old_pass: this.formNewPass.old_pass,
                new_pass: this.formNewPass.new_pass
            });

            // Handling response
            if(res.status == 0){
                if(!this.errors.includes('Špatné heslo.'))
                this.errors.push("Špatné heslo.", 'error');
                this.formNewPass.old_pass = '';
                return false;
            } else if (res.msg == "Success."){
                this.$alerts.toast('Heslo úspěšně změněno.', 'success');
                this.formNewPass.old_pass = '';
                this.formNewPass.new_pass = '';
                this.formNewPass.new_pass2 = '';
                this.$v.$reset();
            }
            else   
                this.errors.push(res.msg);
            
        },

        /**
         * Function for checking if invoice info is specified
         */
        checkInvoiceInfo(){
            let complete = true;
            Object.keys(this.invoiceInfoForm).forEach(key => {
                if (this.invoiceInfoForm[key] == '' || this.invoiceInfoForm[key] == null){
                    complete = false;
                    return 0;
                }
            });
            return complete;
        },
    
        /**
         * Function for getting data from DB about invoicing
         */
        getInvoiceInfo(){
            console.log("fetch");
            if (this.user.cus == 0) {
                this.fetchInvoiceInfo().then(() => {
                // this.invoiceInfoForm.company_name = this.invoiceInfo.company_name;
                this.invoiceInfoForm.ico = this.invoiceInfo.ico;
                this.invoiceInfoForm.dic = this.invoiceInfo.dic;
                this.taxed = this.invoiceInfo.taxed;
                this.invoice = this.invoiceInfo.invoice;
                this.reservation = this.invoiceInfo.reservation;
                this.openingTimeFrom = this.invoiceInfo.openingFrom;
                this.openingTimeTo = this.invoiceInfo.openingTo;
                this.invoiceInfoForm.street = this.invoiceInfo.street;
                this.invoiceInfoForm.city = this.invoiceInfo.city;
                this.invoiceInfoForm.zip = this.invoiceInfo.zip;
                this.invoiceInfoForm.bank_account = this.invoiceInfo.bank_account;
                this.invoiceInfoForm.business_register = this.invoiceInfo.business_register;
                this.custom_invoice_number = this.invoiceInfo.custom_invoice_number;
                this.invoiceSettingsForm.default_invoice_number = this.invoiceInfo.default_invoice_number;

                
                this.fetchInvoiceNumber().then((invoice_number) => {
                    if(!this.invoiceInfo.default_invoice_number) {
                         this.invoiceSettingsForm.default_invoice_number = invoice_number
                    }
                    this.nextInvoiceNumber = invoice_number
                })

            });
            } else {
                console.log("neni cus")
            }
            
        },

        /**
         * Function for applying changes in for to the DB
         */
        async changeInvoiceInfo(reservationOnly = false){
            if (reservationOnly)
            {
                return this.updateInvoiceInfo({
                    // company_name: this.invoiceInfoForm.company_name,
                    reservation: this.reservation,
                    openingFrom: this.openingTimeFrom,
                    openingTo: this.openingTimeTo
                }).then(() => {
                    this.$alerts.toast('Změny úspěšně zapsány', 'success')
                    this.editTgl = false;
                }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'))
            }

            if (this.$v.invoiceInfoForm.$invalid) 
                return 0;

            if (this.custom_invoice_number && this.$v.invoiceSettingsForm.$invalid && this.$v.invoiceSettingsForm.default_invoice_number.$dirty)
                return 0;

            this.updateInvoiceInfo({
                // company_name: this.invoiceInfoForm.company_name,
                def_plan_id: this.billing_plan_id,
                def_type_id: 3, // default plan is invoice
                ico: this.invoiceInfoForm.ico,
                dic: this.invoiceInfoForm.dic,
                taxed: this.taxed,
                invoice: this.invoice,
                reservation: this.reservation,
                street: this.invoiceInfoForm.street,
                city: this.invoiceInfoForm.city,
                zip: this.invoiceInfoForm.zip,
                bank_account: this.invoiceInfoForm.bank_account,
                business_register: this.invoiceInfoForm.business_register,
                openingFrom: this.openingTimeFrom,
                openingTo: this.openingTimeTo,
                custom_invoice_number: this.custom_invoice_number,
                default_invoice_number: this.invoiceSettingsForm.default_invoice_number
            }).then(() => {
                this.$alerts.toast('Změny úspěšně zapsány', 'success')
                this.editTgl = false;
            }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'))
        },

        /**
         * Function for writing selected plan into the DB
         */
        selectPlan(id){
            if (this.$v.invoiceInfoForm.$invalid) {
                this.$alerts.message('Chybějící nebo špatné fakturační údaje!', 'Pro výběr plánu předplatného a k platbě je nutné doplnit správné fakturační údaje.', 'warning');
                return 0;
            } else if(!this.group.phone) {
                Vue.swal({
                    title: "Chybějící nebo špatné telefonní číslo!",
                    showCancelButton: true,
                    cancelButtonText: "Zrušit",
                    confirmButtonText: "Potvrdit",
                    icon: "warning",
                    html: `
                        <span>Prosím o vyplnění kontaktního telefonu pro fakturaci. Číslo vyplňte ve formátu: +420111222333, předvolba je nepovinná</span>
                        <div style="display: flex; flex-direction: column; gap: 0.8rem; margin-top: 15px;">
                            <div style="display: flex; flex-direction: row; justify-content: space-between">
                                <span>Telefon:</span>
                                <input id="phone" type="text" placeholder="+420123456789" /> 
                            </div> 
                            <span id="error-field" class="help-block"></span>
                        </div>
                    `,
                    onOpen: () => {
                        document.querySelector("#error-field").innerHTML = "";
                        document.querySelector("#phone").addEventListener('input', function (evt) {
                            if(!this.value.match(/(^(\+\d{1,3})*\d{9}$)/)) {
                                document.querySelector("#error-field").innerHTML = "Neplatný formát telefonního čísla!"
                            } else if(this.value.length == 0) {
                                document.querySelector("#error-field").innerHTML = "Musíte zadat telefonní číslo!"
                            } else {
                                document.querySelector("#error-field").innerHTML = "";
                            }
                        });
                    },
                    preConfirm: () => {
                        return {
                            phone: document.querySelector("#phone").value
                        } 
                    }
                }).then( e => {
                    if(e.isConfirmed) {
                        if(e.value != null && e.value.phone.length > 0) {
                            if(e.value.phone.match(/(^(\+\d{1,3})*\d{9}$)/)) {
                                this.setPhone({phone: e.value.phone}).then( (res) => {
                                    if(res == "Success.") {
                                        this.fetchUser().then( () => {
                                            this.selectPlan(id);
                                        });
                                    } else {
                                        this.$alerts.message('Nastala chyba', res, 'error')
                                    }
                                });
                            } else {
                                this.$alerts.message('Neplatný formát telefonního čísla!', '', 'error')
                            }
                        } else {
                            this.$alerts.message('Musíte zadat telefonní číslo!', '', 'error')
                        }
                    }     
                });
                return 0;
            }
            else{
                this.$swal({
                    title: "Vybrat tento plán?",
                    text: "Po výběru plánu Vám bude vystavena faktura a zaslána na email. V případě nezaplacení faktury do 10 dnů po splatnosti, je ANAFRA oprávněná znemožnit přístup do aplikace.",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Potvrdit",
                    cancelButtonText: "Zrušit výběr"
                }).then((result) => { 
                    if (result.value) { // if confirmed
                        this.billing_plan_id = id;
                        this.changeInvoiceInfo().then(() => this.fetchUser());
                    }
                });
            }    
        }, 

        /**
         * Cancels plan so no further billing will be held agains user
         */
        cancelPlan(){
            if (this.$v.invoiceInfoForm.$invalid) {
                this.$alerts.message('Chybějící nebo špatné fakturační údaje!', 'Pro výběr plánu předplatného a k platbě je nutné doplnit správné fakturační údaje.', 'warning');
                return 0;
            }
            else{
                this.$swal({
                    title: "Opravdu chcete zrušit předplatné?",
                    text: "Po zrušení plánu Vám budou odebrány výhody placeného plánu s okamžitou platností! Doporučujeme tedy plán zrušit před koncem měsíce, abyste mohli výhody čerpat na maximum.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Potvrdit",
                    cancelButtonText: "Zrušit výběr"
                }).then((result) => { 
                    if (result.value) { // if confirmed
                        this.billing_plan_id = 0;
                        this.changeInvoiceInfo().then(() => this.fetchUser());
                    }
                });
            }    
        }
    },

    async created()
    {
        this.headColumns = [
            { name: 'Jméno' },
            { name: 'Příjmení' },
            { name: 'Email' }
        ]

        this.fetchEmployees();
        this.fetchUser().then( (user) => {
            this.getInvoiceInfo()
            if (user.cus == 0)
                this.billing_plan_id = this.group.timespent.plan_id;
        });
        
        this.page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>

<style scoped>
a {
    cursor: pointer;
}
</style>